import {
    getModule,
    Module,
    MutationAction,
    VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { SettingRequest } from "@/api/line-miniapp/request";
import { SettingResponse } from "@/api/line-miniapp/response";
import * as LineMiniappAPI from "@/api/line-miniapp";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "line-miniapp/get";

/**
 * LINEミニアプリ設定取得API（/get-line-setting）のレスポンスを管理するストアモジュール
 */
@Module({dynamic: true, store, name: MODULE_NAME, namespaced: true})
class Get extends VuexModule {
    // state
    settingResponse: SettingResponse = {} as SettingResponse;

    // getters
    get isSuccess() {
        return isSuccess(this.settingResponse);
    }

    get getMessage() {
        return this.settingResponse.message;
    }

    get getId() {
        return this.settingResponse.results ? this.settingResponse.results.id : null;
    }

    get getShopId() {
        return this.settingResponse.results.shopId;
    }

    get getLineAuthCoupon() {
        return this.settingResponse.results ? this.settingResponse.results.lineAuthCoupon : null;
    }

    get getLineAuthTicket() {
        return this.settingResponse.results ? this.settingResponse.results.lineAuthTicket : null;
    }

    get getLiffUrl() {
        return this.settingResponse.results ? this.settingResponse.results.liffUrl : '';
    }

    get getLineType() {
        return this.settingResponse.results ? this.settingResponse.results.data.lineType : [];
    }

    get getWriteFlg() {
        return this.settingResponse.results.writeFlg;
    }

    get getChannelId() {
        return this.settingResponse.results.clientId;
    }

    get getChannelSecret() {
        return this.settingResponse.results.clientSecret;
    }

    get getChannelLiffId() {
        return this.settingResponse.results.liffId;
    }

    get getStampSendDate() {
        return this.settingResponse.results.messageDateStamp;
    }

    get getCouponSendDate() {
        return this.settingResponse.results.messageDateCoupon;
    }

    get getCompleteSendDate() {
        return this.settingResponse.results.messageDateTicket;
    }

    get getStampSendTime() {
        return this.settingResponse.results.massageTimeStamp;
    }

    get getCouponSendTime() {
        return this.settingResponse.results.massageTimeCoupon;
    }

    get getCompleteSendTime() {
        return this.settingResponse.results.massageTimeTicket;
    }

    // MutationActions
    @MutationAction
    async setting(settingRequest: SettingRequest) {
        const settingResponse = await LineMiniappAPI.setting(settingRequest);
        return {
            settingResponse
        };
    }

    @MutationAction
    async clear() {
        return {
            settingResponse: {} as SettingResponse
        };
    }
}

export default getModule(Get);
