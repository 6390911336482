import service from "@/api/service";
import { DetailRequest, RegisterRequest } from "@/api/stamp-setting/request";
import { DetailResponse } from "@/api/stamp-setting/response";
import { CommonResponse } from "@/api/response";

/**
 * スタンプ条件設定取得APIをコールします。
 *
 * @param detailRequest スタンプ条件一覧検索のリクエストボディ
 * @return DeleteResponse
 */
export async function detail(detailRequest: DetailRequest) {
  const response = await service.post("/get-stamp-setting", detailRequest);
  return response.data as DetailResponse;
}

/**
 * スタンプ条件登録更新APIをコールします。
 *
 * @param registerRequest
 * @return CommonResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-stamp-setting", registerRequest);
  return response.data as CommonResponse;
}
