import { Component, Prop, Mixins } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import UIModalSpecialDay from "@/components/UlModalSpecialDay.vue";
import UlFileRegister from "@/components/UlFileRegister.vue";
import {
  UlFileRegisiterTabType,
  UIModalSpecialDayModalType
} from "@/utils/enums";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import { TableOptions } from "@/api/request";
import { DetailRequest, RegisterRequest } from "@/api/stamp-setting/request";
import { SettingRequest } from "@/api/line-miniapp/request";
import { GetRequest as AutoStampCardsGetRequest } from "@/api/auto-stamp-cards/request";
import { GetRequest as SpecialSettingGetRequest } from "@/api/special-setting/request";
import {
  DetailResult,
  DetailSpecial,
  DetailAuth,
  DetailLineAuth
} from "@/api/stamp-setting/response";
import LineSettingGetAPI from "@/store/line-miniapp/get";
import ShopAgreementAPI from "@/store/shop-agreement/get";
import { GetResult, GetSpecialDay } from "@/api/special-setting/response";
import { SpecialDayModalResponse } from "@/utils/interfaces";
import StampSettingDetail from "@/store/stamp-setting/detail";
import StampSettingRegister from "@/store/stamp-setting/register";
import AutoStampCardsGet from "@/store/auto-stamp-cards/get";
import SpecialSettingGet from "@/store/special-setting/get";
import Flash, { ErrorAlert } from "@/store/common/flash";
import Loading from "@/store/loading";
import QRCode, { QRCodeToDataURLOptions } from "qrcode";
import { GetRequest as GetAgreementRequest } from "@/api/shop-agreement/request";

interface Special extends DetailSpecial, GetResult {
  isEdited: boolean;
  imageEditFlg: 0 | 1;
  imageId: number | null;
}

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIFormRow,
    UIDatePicker,
    UIModalSpecialDay,
    UlFileRegister,
    VueQrcode
  }
})
export default class Setting extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  id!: string; // スタンプID

  // タイトル
  headingMain = "スタンプ";
  headingSub = "Stamps";
  agrement_flg = false;

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  qrOptions = {
    errorCorrectionLevel: "M",
    margin: 10,
    scale: 4,
    width: 200
  };

  tableQrOptions = {
    errorCorrectionLevel: "M",
    margin: 2,
    scale: 4,
    width: 60
  };

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  allTableHeaders = [
    { text: "店舗名", value: "shopName", sortable: false },
    { text: "", value: "space", sortable: false },
    { text: "スタンプ画像", value: "image", sortable: false },
    { text: "認証コード", value: "authCode", sortable: false }
  ];

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  singleTableHeaders = [
    { text: "店舗名", value: "shopName", sortable: false },
    { text: "スタンプ画像", value: "image", sortable: false },
    { text: "認証コード", value: "authCode", sortable: false },
    { text: "スペシャルデー", value: "special", sortable: false },
    { text: "", value: "edit", sortable: false }
  ];

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  qrTableHeadersOwned = [
    { text: "店舗名", value: "shopName", sortable: false },
    { text: "", value: "space", sortable: false },
    { text: "QRコード（オウンドアプリ）", value: "image", sortable: false },
    {
      text: "1回の認証で付与するスタンプ数",
      value: "stampNum",
      sortable: false
    }
  ];

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  qrTableHeadersLine = [
    { text: "店舗名", value: "shopName", sortable: false },
    { text: "", value: "space", sortable: false },
    { text: "QRコード（LINEミニアプリ）", value: "image", sortable: false },
    {
      text: "1回の認証で付与するスタンプ数",
      value: "stampNum",
      sortable: false
    }
  ];

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  beaconTableHeaders = [
    { text: "店舗名", value: "shopName", sortable: false },
    { text: "", value: "space", sortable: false },
    { text: "Major", value: "major", sortable: false },
    { text: "Minor", value: "minor", sortable: false },
    {
      text: "1回の認証で付与するスタンプ数",
      value: "stampNum",
      sortable: false
    }
  ];

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  wifiTableHeaders = [
    { text: "店舗名", value: "shopName", sortable: false },
    { text: "", value: "space", sortable: false },
    { text: "SSID", value: "ssid", sortable: false },
    {
      text: "1回の認証で付与するスタンプ数",
      value: "stampNum",
      sortable: false
    }
  ];

  allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  tabType = UlFileRegisiterTabType.Photo;
  initStampImageUrl = null as string | null;

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = {} as TableOptions;

  inputParams = {} as DetailResult;

  specialInputParams = [] as Special[];

  // スペシャル設定モーダル
  showModal = false;
  modalType = UIModalSpecialDayModalType.All;
  selectingSpecial = null as Special | null;
  specialDayModalResponse = null as SpecialDayModalResponse | null;

  // QRコードダイアログ
  showQrDialog = false;
  dialogAuthCode = "";

  liffUrl = "";

  // パンくず
  get breadCrumbs() {
    return [
      { text: "アピール", disabled: true },
      { text: "スタンプ", disabled: true },
      { text: "店舗一覧", disabled: false, to: { name: "stamps" } },
      {
        text: "スタンプ一覧",
        disabled: false,
        to: {
          name: "stamps-list",
          params: { shopId: this.shopId },
          query: { shopName: this.shopName }
        }
      },
      { text: "条件設定", disabled: true }
    ];
  }

  // 契約状態
  agreements = {
    ap: false,
    line: false,
    web: false
  };

  /**
   * クエリパラメータから店舗IDを返却する（必須）
   */
  get shopId() {
    if (this.$route.query.shopId) {
      return this.$route.query.shopId;
    } else {
      return "";
    }
  }

  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get shopName() {
    if (this.$route.query.shopName) {
      return this.$route.query.shopName;
    } else {
      return "";
    }
  }

  get loading() {
    return Loading.isLoading;
  }

  get specialItems() {
    return this.specialInputParams;
  }

  get authItems() {
    let authQrItems: DetailAuth[] | null = [];
    if (this.inputParams.auth) {
      this.inputParams.auth.forEach(authItem => {
        if (authItem.authType == 2 || authItem.authType == null) {
          authItem.authType = 2;
          authQrItems!.push(authItem);
        }
      }, this);
    }
    return authQrItems;
  }

  get authItemsLine() {
    let authQrItems: DetailLineAuth[] | null = [];
    if (this.inputParams.lineAuth && this.agrement_flg) {
      this.inputParams.lineAuth.forEach(authItem => {
          authQrItems!.push(authItem);
      }, this);
    }
    return authQrItems;
  }

  get stampItems() {
    return AutoStampCardsGet.getItems;
  }

  get mainSpecialItem() {
    for (let special of this.specialInputParams) {
        return special;
    }
    return {} as Special;
  }

  get activeUseTypeTab() {
    return this.inputParams.bulkFlg === 0 ? 0 : 1;
  }

  set activeUseTypeTab(val) {
    if (val === 1) {
      this.inputParams.bulkFlg = 1;
    } else {
      this.inputParams.bulkFlg = 0;
    }
  }

  get activeAuthTypeTab() {
    switch (this.inputParams.authType) {
      case 1:
        return 0;
      case 2:
        return 1;
      case 3:
        return 2;
      case 4:
        return 3;
      default:
        return 0;
    }
  }

  set activeAuthTypeTab(val) {
    switch (val) {
      case 0:
        this.inputParams.authType = 1;
        break;
      case 1:
        this.inputParams.authType = 2;
        break;
      case 2:
        this.inputParams.authType = 3;
        break;
      case 3:
        this.inputParams.authType = 4;
        break;
      default:
        this.inputParams.authType = 1;
    }
  }

  weekDayItems = [
    { text: "月曜日", value: 1 },
    { text: "火曜日", value: 2 },
    { text: "水曜日", value: 3 },
    { text: "木曜日", value: 4 },
    { text: "金曜日", value: 5 },
    { text: "土曜日", value: 6 },
    { text: "日曜日", value: 0 }
  ];

  get specialTotalCount() {
    return this.specialInputParams.length;
  }

  get authTotalCount() {
    if (this.inputParams.auth) {
      return this.inputParams.auth.length;
    } else {
      return 0;
    }
  }

  get beaconUUID() {
    if (this.inputParams.auth && this.inputParams.auth.length > 0) {
      for (let auth of this.inputParams.auth) {
        if (auth.isMain === 1) {
          return auth.beaconUuid;
        }
      }
    }
    return "";
  }

  set beaconUUID(uuid) {
    if (this.inputParams.auth && this.inputParams.auth.length > 0) {
      for (let auth of this.inputParams.auth) {
        if (auth.isMain === 1) {
          auth.beaconUuid = uuid;
        }
      }
    }
  }

  /**
   * 契約が未登録かの判定
   * @return true:契約が 未登録 false:契約が 登録済
   */
  isNotAgreement(): boolean {
    const result = this.agreements.ap === false && this.agreements.line === false && this.agreements.web === false;
    return result;
  }

  async created() {
    if (!this.shopId) {
      await this.$router.push({ name: "stamps" });
      await Flash.setErrorNow({
        message: "パラメータが不足しています。",
        showReloadButton: false
      } as ErrorAlert);
      return;
    }
    const requestAgreement = {
      shopId: this.shopId
    } as GetAgreementRequest;
    await ShopAgreementAPI.get(requestAgreement);
    if (!ShopAgreementAPI.isSuccess) {
      await Flash.setErrorNow({
        message: ShopAgreementAPI.getMessage,
        showReloadButton: true
      } as ErrorAlert);
      return;
    } else {
      const AgreementItems = ShopAgreementAPI.getagreementsList;
      AgreementItems.forEach((item: { agreementsType: number }) => {
        if (item.agreementsType == 1) {
          this.agreements.ap = true;
        } else if (item.agreementsType == 2) {
          this.agreements.line = true;
          this.agrement_flg = true;
        } else if (item.agreementsType == 3) {
          this.agreements.web = true;
        }
      });
    }
    const request = {
      id: Number(this.id),
      shopId: Number(this.shopId)
    } as DetailRequest;
    await StampSettingDetail.detail(request);
    if (StampSettingDetail.isSuccess) {
      this.syncInputParams();
    } else {
      await Flash.setErrorNow({
        message: StampSettingDetail.getMessage,
        showReloadButton: true
      } as ErrorAlert);
      return;
    }
    if (this.inputParams.autoFlg === 1) {
      const stampRequest = {
        id: Number(this.id),
        shopId: Number(this.shopId)
      } as AutoStampCardsGetRequest;
      await AutoStampCardsGet.get(stampRequest);
      if (!AutoStampCardsGet.isSuccess) {
        await Flash.setErrorNow({
          message: AutoStampCardsGet.getMessage,
          showReloadButton: true
        } as ErrorAlert);
      }
    }
    const requestLine = {
      shopId: this.shopId
    } as SettingRequest;
    await LineSettingGetAPI.setting(requestLine);
    if (LineSettingGetAPI.isSuccess) {
      this.QrCodeLiffUrl(LineSettingGetAPI.getLiffUrl);
    }
  }

  async beforeDestroy() {
    await StampSettingDetail.clearResponse();
    await StampSettingDetail.clearResponse();
    await AutoStampCardsGet.clearResponse();
    await SpecialSettingGet.clearResponse();
  }

  monthlyDateSetting(specialDays: GetSpecialDay | null) {
    if (!specialDays) {
      return "";
    }
    let str = "";
    if (specialDays.monthStart === 1) {
      str = "月初";
    }
    if (specialDays.monthEnd === 1) {
      if (str.length > 0) {
        str += "、";
      }
      str += "月末";
    }
    if (specialDays.specific && specialDays.specific.length > 0) {
      if (str.length > 0) {
        str += "、";
      }
      str += specialDays.specific
        .map(s => {
          return `${s}日`;
        })
        .join("、");
    }
    return str;
  }

  joinWeekNames(week: number[][] | null) {
    if (!week || !week[0]) {
      return "";
    }
    const w = [
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日",
      "日曜日"
    ];
    let s = [] as string[];
    for (let i = 0; i < week[0].length; i++) {
      if (week[0][i] === 1) {
        s.push(w[i]);
      }
    }
    return s.join("、");
  }

  joinMonthlyWeekNames(week: number[] | null) {
    if (!week || week.length <= 0) {
      return "";
    }
    const w = [
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日",
      "日曜日"
    ];
    let s = [] as string[];
    for (let i = 0; i < week.length; i++) {
      if (week[i] === 1) {
        s.push(w[i]);
      }
    }
    return s.join("、");
  }

  showWeek(week: number[] | null) {
    if (!week) {
      return false;
    }
    let weekCount = 0;
    for (const w of week) {
      weekCount += w;
    }
    return weekCount > 0;
  }

  async setStampFile(data: string, id: number | null) {
    if (this.mainSpecialItem) {
      //await this.fetchMainSpecialSetting();
      this.mainSpecialItem.isEdited = true;
      this.mainSpecialItem.imageEditFlg = 1;
      this.mainSpecialItem.image = data;
      this.mainSpecialItem.imageId = id;
    }
    if (this.specialItems) {
      this.specialItems.forEach(function(item) {
        item.isEdited = true;
        item.imageEditFlg = 1;
        item.image = data;
        item.imageId = id;
      });
    }
  }

  async unsetStampFile() {
    if (this.mainSpecialItem) {
      await this.fetchMainSpecialSetting();
      this.mainSpecialItem.isEdited = true;
      this.mainSpecialItem.imageEditFlg = 1;
      this.mainSpecialItem.image = null;
      this.mainSpecialItem.imageId = null;
    }
    if (this.specialItems) {
      this.specialItems.forEach(function(item) {
        item.isEdited = true;
        item.imageEditFlg = 1;
        item.image = null;
        item.imageId = null;
      });
    }
  }

  goBack() {
    this.$router.back();
  }

  async onSave() {
    await StampSettingRegister.register(this.createRegisterRequest());
    if (StampSettingRegister.isSuccess) {
      await this.redirectWithSuccessAlert(
        "条件設定設定を保存しました。",
        `/stamps/${this.shopId}/list`,
        { shopName: this.shopName }
      );
    } else {
      await Flash.setErrorNow({
        message: StampSettingRegister.getMessage,
        showReloadButton: false
      });
    }
  }

  onDownloadQrImage(item: DetailAuth) {
    if (item.authCode) {
      QRCode.toDataURL(
        item.authCode,
        {
          errorCorrectionLevel: "M",
          scale: 4
        } as QRCodeToDataURLOptions,
        (error: Error, url: string) => {
          if (error) {
            Flash.setErrorNow({
              message: error.message,
              showReloadButton: false
            } as ErrorAlert).then();
          } else {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.download = `${item.shopName}_qrcode.png`;
            a.href = url;
            a.click();
            a.remove();
            setTimeout(() => {
              URL.revokeObjectURL(url);
            }, 1e4);
          }
        }
      );
    }
  }

  QrCodeLiffUrl(item: string) {
    this.liffUrl = item;
  }

  onDownloadQrImage2(item: DetailLineAuth) {
    if (item.authCode) {
      QRCode.toDataURL(
        `${this.liffUrl}?stamp_id=${item.stampCardsId}&code=${item.authCode}`,
        {
          errorCorrectionLevel: "M",
          scale: 4
        } as QRCodeToDataURLOptions,
        (error: Error, url: string) => {
          if (error) {
            Flash.setErrorNow({
              message: error.message,
              showReloadButton: false
            } as ErrorAlert).then();
          } else {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.download = `${item.shopName}_qrcode.png`;
            a.href = url;
            a.click();
            a.remove();
            setTimeout(() => {
              URL.revokeObjectURL(url);
            }, 1e4);
          }
        }
      );
    }
  }

  openQrDialogCallback(item: DetailAuth) {
    if (item.authCode) {
      this.showQrDialog = true;
      this.dialogAuthCode = item.authCode;
    }
  }
  openQrDialogCallback2(item: DetailLineAuth) {
    if (item.authCode) {
      this.showQrDialog = true;
      this.dialogAuthCode = `${this.liffUrl}?stamp_id=${item.stampCardsId}&code=${item.authCode}`;
    }
  }
  openModalAllCallback() {
    if (this.mainSpecialItem) {
      this.modalType = UIModalSpecialDayModalType.All;
      this.initModal(this.mainSpecialItem).then();
    }
  }

  openModalSingleCallback(item: Special) {
    this.modalType = UIModalSpecialDayModalType.Single;
    this.initModal(item).then();
  }

  doSaveOnModal(response: SpecialDayModalResponse) {
    if (this.selectingSpecial) {
      this.$set(this.selectingSpecial, "dayType", response.dayType);
      this.$set(this.selectingSpecial, "stampCount", response.stampCount);
      this.$set(this.selectingSpecial, "imageEditFlg", response.imageEditFlg);
      this.$set(this.selectingSpecial, "imageId", response.imageId);
      this.$set(this.selectingSpecial, "image", response.imagePath);
      this.$set(this.selectingSpecial, "specialDays", response.specialDays);
      this.$set(this.selectingSpecial, "weekStart", response.weekStart);
      this.$set(this.selectingSpecial, "weekEnd", response.weekEnd);
      this.$set(this.selectingSpecial, "isEdited", true);
    }
  }

  private syncInputParams() {
    const detail = StampSettingDetail.getDetail;
    if (detail.special) {
      for (const special of detail.special) {
        this.specialInputParams.push({
          ...special,
          isEdited: false,
          imageEditFlg: 0,
          imageId: null
        } as Special);
      }
      if (detail.bulkFlg === 0) {
        this.initStampImageUrl = this.mainSpecialItem.image;
      }
    }
    delete detail.special;
    this.inputParams = {...detail};
    this.inputParams.bulkFlg =
        this.inputParams.bulkFlg === null ? 0 : this.inputParams.bulkFlg;

    if (this.inputParams.authType === null) {
      if (this.agreements.ap === false && this.isNotAgreement() === false && this.agreements.line === true) {
        this.inputParams.authType = 2;
      } else {
        this.inputParams.authType = 1;
      }
    }
    if (this.inputParams.auth) {
      if (this.inputParams.lineAuth.length == 0) {
        //this.inputParams.lineAuth = [] as DetailLineAuth[];
        let lineAuth:DetailLineAuth[] = [];

        this.inputParams.auth.forEach((val: DetailAuth) => {
          let item = {} as DetailLineAuth;
          item.id = val.id;
          item.shopId = val.shopId;
          item.isMain = val.isMain;
          item.shopName = val.shopName;
          item.authCode = val.authCode;
          item.stampCount = null;
          item.authType = 5;
          if (this.agrement_flg) {
            lineAuth.push(item);
          }
        });
        this.inputParams.lineAuth = lineAuth;
      }
    }
  }

  private createRegisterRequest() {
    const request = {
      id: Number(this.id),
      shopId: Number(this.shopId),
      autoFlg: this.inputParams.autoFlg
    } as RegisterRequest;
    if (this.inputParams.autoFlg === 1) {
      // 自動設定の場合
      request.oneTimeFlg = this.inputParams.oneTimeFlg;
      request.stampCardsIds = this.inputParams.stampCardsIds;
    } else {
      request.bulkFlg = this.inputParams.bulkFlg;
      if (this.specialInputParams && this.specialInputParams.length > 0) {
        // if (this.inputParams.special && this.inputParams.special.length > 0) {
        request.special = [];
        for (let special of this.specialInputParams) {
          if (!special.isEdited) {
            continue;
          }
          const val = { ...special };
          delete val.isEdited;
          if (val.imageEditFlg === 0 || val.imageId != null) {
            delete val.image;
          }
          // 「個別設定」でスペシャルデー未設定の場合、APIのバリデーション回避のため1を設定
          if (val.dayType == 0) {
            val.dayType = 1;
          }
          request.special.push(val);
        }
      }
      request.authFlg = this.inputParams.authFlg;
      request.authType = this.inputParams.authType;

      if (this.agreements.ap === true || this.isNotAgreement() === true) {
        if (this.inputParams.auth && this.inputParams.auth.length > 0) {
          request.auth = [];
          for (let auth of this.inputParams.auth) {
            const val = { ...auth };
            delete val.isMain;
            delete val.shopName;
            delete val.authCode;
            request.auth.push(val);
          }
        }
      } else {
        request.auth = [];
      }

      if (this.inputParams.lineAuth && this.inputParams.lineAuth.length > 0) {
        request.lineAuth = [];
        for (let lineAuth of this.inputParams.lineAuth) {
          const val = { ...lineAuth };
          delete val.isMain;
          delete val.shopName;
          delete val.authCode;
          request.lineAuth.push(val);
        }
      }      
      request.onlyOneFlg = this.inputParams.onlyOneFlg;
      request.stampInterval = this.inputParams.stampInterval;
    }
    return request;
  }

  private async initModal(special: Special) {
    this.showModal = true;
    this.selectingSpecial = special;
    if (!special.isEdited && special.id) {
      // 値が編集済み出ない場合はAPIで取得する
      await this.getSpecialSettingDetail(special);
      if (SpecialSettingGet.isSuccess) {
        special = { ...special, ...SpecialSettingGet.getResults };
      }
    }
    const imagePath = special.image;
    delete special.image;
    this.specialDayModalResponse = {
      ...special,
      imagePath: imagePath,
      specialPointType: null,
      specialPointCount: null,
      specialPointRate: null,
      imageEditFlg: 0,
      imageId: null,
      dayType: special.dayType === undefined ? 1 : special.dayType
    };
  }

  private async getSpecialSettingDetail(special: Special) {
    await SpecialSettingGet.get({
      id: special.id,
      shopId: special.shopId,
      bulkFlg: special.bulkFlg
    } as SpecialSettingGetRequest);
    if (!SpecialSettingGet.isSuccess) {
      await Flash.setErrorNow({
        message: SpecialSettingGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  private async fetchMainSpecialSetting() {
    if (!this.mainSpecialItem) {
      return;
    }
    if (!this.mainSpecialItem.isEdited) {
      await this.getSpecialSettingDetail(this.mainSpecialItem);
      if (SpecialSettingGet.isSuccess) {
        const result = SpecialSettingGet.getResults;
        this.mainSpecialItem.isEdited = true;
        this.mainSpecialItem.stampCardsId = result.stampCardsId;
        this.mainSpecialItem.dayType = result.dayType;
        this.mainSpecialItem.specialDays = result.specialDays;
        this.mainSpecialItem.weekStart = result.weekStart;
        this.mainSpecialItem.weekEnd = result.weekEnd;
      }
    }
  }
}
