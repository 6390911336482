import service from "@/api/service";
import { GetResponse } from "@/api/special-setting/response";
import { GetRequest } from "@/api/special-setting/request";

/**
 * スタンプカードスペシャルデー情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-special-setting", getRequest);
  return response.data as GetResponse;
}
